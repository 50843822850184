.img_con {
    position: relative;
    width: 100%;
}
  
.img_src {
    opacity: 1;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
    border-radius: 50% !important;
}
  
.img_middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
  
.img_con:hover .img_src {
    opacity: 0.2;
}
  
.img_con:hover .img_middle {
    opacity: 1;
}

.center_spinner {
    position: absolute;
    margin: auto;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
} 

.loader_con {
    line-height: 105px;
    height: 105px;
    text-align: center;
}

.trash {
    opacity:0%;
    transition: transform 300ms;
}
.tableRow:hover .trash {
    opacity: 100%;
    transform: translateX(-8px);
}

/* ipad pro size */
@media all and (max-width: 1024px) {
    .trash {
        opacity:100%;
        transition: none;
    }
    .tableRow:hover .trash {
        opacity: 100%;
        transform: translateX(0px);
    }
}

.header_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;;
}

.header_selected {
    padding-top: 10px;
    color: #212529;
    margin-right: 30px;
    font-weight: bold;

}

.header_not_selected {
    color: #6c757d;
    margin-right: 20px;
    font-weight: bold;
    padding-top: 20px

}

.header_not_selected:hover {
    color: #212529;
    cursor: pointer;
}


.conversation_profile_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.message_profile_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:10px;
}


.message_profile {
    padding-left: 17px;
    font-weight: bold;
    align-self: flex-start;
}


.message_body {
    padding-left: 52px;
    padding-right: 160px;
}

.message_date { 
    margin-left: auto;
    color: #6c757d;
    font-size:13px;
}

.convo_date { 
    color: #6c757d;
    font-size:13px;
    margin-right: auto;
}


.progress_card {
    border: 1px solid #212529;
    border-radius:5px;
    padding: 20px;
}


.progress_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.progress_test{
    margin-left: 10px;
    padding-top: 5px;
    flex-grow: 1;
}

.progress_icon{
    flex-grow: 1;
}

.class_outline {
    border: 1px solid rgb(49, 35, 101);
    border-radius: 10px;
    padding: 25px;
    width: 250px;
    padding: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
    color:#190d44;
    background-color: #f6f5fa !important;
}

.class_outline:hover{
    transition: .5s ease;
    box-shadow: 0 5px 15px rgb(0 0 0 / 0.2);
    cursor: pointer;
    border-width:2px;
    transition: all .2s ease-in-out;
  }

  .btn_grad {
    background-image: linear-gradient(to right, #8b25fd, #b646fd) !important;
    margin: 10px;
    text-align: center;
    transition: 0.5s;
    border:none;
    color: white;            
    border-radius: 5px;
    padding: 8px 10px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
 
  }

  .btn_grad:hover {
    background-position: right center; /* change the direction of the change here */
    text-decoration: none;
    transform: translateY(-3px);
    -webkit-filter: brightness(70%);
     -webkit-transition: all .5s ease;
     -moz-transition: all .5s ease;
     -o-transition: all .5s ease;
     -ms-transition: all .5s ease;
     transition: all .5s ease;
  }